.subscriber-nav-tab>ul{
    padding: 0px;
    list-style: none;
    display: flex;
}
.subscriber-nav-tab>ul>li{
    padding: 15px 0px;
    padding-left: 0px;
    margin-right: 35px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 450;
    
}
.subscriber-nav-tab>ul>li>a{
    padding: 2vh 0px;
    color: #635f5f;
    text-decoration: none;
}
.subscriber-nav-tab>ul>li>a:hover{
    color: #262626;
    border-bottom: 0.5px solid #8f8b8b;
}
.subscriber-nav-tab>ul>li>a.active{
    color: #262626;
    border-bottom: 2px solid #2081E3;
    padding: 2vh 0px;
}