.copy-paste-route{
    margin: 1.25em 0px;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}
.copy-paste-route>p{
    font-size: 0.7em;
    font-weight: 500;
}
.copy-paste-heading{
    margin: 1em 0px;
}
.copy-paste-heading>h1{
    font-weight: 600;
}



.copy-paste-content{
    margin: 5vh 0px;
}