.add-new-account-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* background-color: aquamarine; */
}
.add-new-account-heading>h2{
    font-size: 36px;
}

.add-new-account-route{
    margin: 2vh 0px;
    margin-top: 1vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}
.add-new-account-route>p{
    font-size: 0.8em;
    font-weight: 400;
    /* color: #404040E5; */
}
.add-new-account-content{
    /* height: 20vh; */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1vw 2vw;
}
.add-new-account-content-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: aqua; */

}
.add-new-account-content-1-row{
    margin: 1.5em 0px;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.add-new-account-content-1-row>label{
    /* background-color: aquamarine; */
    color: #262626;
    font-size: 1.1em !important;
    font-weight: 400;
    margin-bottom: 0.7em;
}
.add-new-account-content-1-row-input{
    position: relative;
}
.add-new-account-content-1-row-input>input{
    width: 100%;
    padding: 0.8em 1em;
    outline: none;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    color: #262626;
    padding-right: 3rem;
}
.add-new-account-dropdown{
    margin-top: 3px;
    width: 100%;
    position: absolute;
    background-color: #fcfcfc;
    box-shadow: 1px 3px 8px 2px #f5f5f5;
    border: 1px solid rgb(238, 238, 238);
    z-index: 1;
}
.add-new-account-dropdown>ul{
    padding: 0px;
    list-style: none;
}
.add-new-account-dropdown>ul>li{
    cursor: pointer;
    padding: 10px;
}
.add-new-account-dropdown>ul>li:hover{
    background-color: #D6D6D6;
}





.add-new-account-content-2{
    margin: 1.5em 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #D6D6D6;
    padding-top: 2.5em;
}
.add-new-account-content-2>button{
    
    /* background-color: aqua; */
    font-weight:550;
    font-size:14px;
    padding:0.8em 1.3em;
    color:white;
    margin-left: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    
}

.show-secret-key-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25%;
    right: 10px;
    color: #404040;
    cursor: pointer;
}