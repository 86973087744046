
.accounts-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accounts-heading-btn{
    cursor: pointer;
    padding: 10px 20px;
    background-color: #2081E3;
    border-radius: 4px;
    color: #ffffff;
}
.accounts-content{
    padding: 1vh 0vw;
    background-color: #ffffff;
    border-radius: 6px;
}
.accounts-table{
    width: 100%;
    height: 60vh;
    overflow-y: auto;
}
.accounts-table-heading{
    padding: 4vh 2vw;
    border-bottom: 1px solid #ebebeb;
}
.accounts-table-heading>h1{
    font-size: 1.4em;
    font-weight: 600;
}
.accounts-table::-webkit-scrollbar{
    display: none;
}

.accounts-table>table{
    width: 100%;
    border:"1px solid black";
}
.accounts-table-searching-row>td{
    background-color: aqua;
}

.accounts-table-searching-row>td>input{
    width: 100%;

}




.accounts-table>table>thead>tr>th{
    text-align: left;
    padding: 15px 2vw;
    font-weight: 500;
    color: #2081E2;
    font-size: 1em;
    text-align: center;
}
.accounts-table-thead-input{
    border-top: 1px solid #ebebeb;
}
.accounts-table-thead-input>th{
    width: 70px;
}
.accounts-table-thead-input>th>input{
    width: 70px;
    outline: none;
    border: 1px solid #ebebeb;
    padding: 2px 5px;
    border-radius: 2px;
    font-size: 0.9em;
}
.accounts-table-thead-input>th>input:focus{
    border: 1px solid #000000;

}
.accounts-table>table >tbody>tr{
    border-top: 1px solid #E5E7EB;
}

.accounts-table>table >tbody>tr>td{
    margin: 0;
    min-width: 15px;
    text-align: left;
    padding: 10px 2vw;
    font-weight: 400;
    color: #696969;
    font-size: 1em;
    text-align: center;
}
.accounts-table-collection-data{
    background-color: #EF44441A;
    padding: 5px 15px;
    border-radius: 40px;
    color: red;
    font-size: 0.7em;
}

.account-content-footer{
    margin: 0px 0px;
    padding: 4vh 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E5E7EB;
}
.account-content-footer>div>button{
    padding: 12px 22px;
    margin-left: 10px;
    background-color: #ebebeb;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: rgb(75, 75, 75);
    font-weight: 500;
    font-size: 1em;
}
.account-content-footer>div>p{
    color: rgb(80, 80, 80);
    font-size: 1.2em;
}