.cpe-modal{
    background-color: rgba(85, 83, 83, 0.856);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    padding: 0vh 0px;
    
}
.cpe-modal>div{
    height: 100%;
    padding: 5vh 0px;
    overflow: auto;
}
.cpe-modal>div::-webkit-scrollbar{
    display: none;
}
.cpe-modal-close{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom:1vh ;
}
.cpe-modal-close>div{
    padding: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    border-radius: 100%;
    color: #ffffff;
    cursor: pointer;

}
.cpe-modal-content{
    background-color: #ffffff;
    width: 100%;
    /* height: 90%; */
    border-radius: 4px;
    padding: 3vh 0px;
}
.cpe-modal-content-heading{
    padding: 0px 2.7vw;
}
.cpe-modal-content-heading>h1{
    font-size: 1.3em;
    font-weight: 600;
    margin: 2.5vh 0px;
    margin-bottom: 0vh;
}
.cpe-modal-content-email{
    padding: 4.5vh 2.7vw;
    padding-top: 5vh;
    border-bottom: 1px solid #E5E7EB;
    /* background-color: aqua; */
    /* margin-top: 5vh; */

}
.cpe-modal-content-email-1{
    display: flex;
    align-items: center;
}
.cpe-modal-content-email-1>h1{
    font-size: 1.15em;
    font-weight: 550;

}
.cpe-modal-content-email-1>div{
    background-color: #E5E7EB;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 10px;
}

.cpe-modal-content-email-row-1{
    margin-top: 3.5vh;
    /* display: flex; */

}


.cpe-modal-content-email-row-1-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cpe-modal-content-email-row-1-1-left{
    width: 48%;
    margin-bottom: 2vh;
    /* background-color: aqua; */
}
.cpe-modal-content-email-row-1-1-left>p{
    font-size: 1em;
    font-weight: 400;

}
.cpe-modal-content-email-row-1-1-right{
    width: 48%;
    margin-bottom: 2vh;
    /* background-color: aqua; */
}
.cpe-modal-content-email-row-1-1-right>p{
    font-size: 1em;
    font-weight: 400;

}
.cpe-modal-content-email-row-1-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cpe-modal-content-email-row-1-2-left{
    width: 48%;
    /* background-color: aqua; */
}
.cpe-modal-content-email-row-1-2-right{
    width: 48%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
}
.cpe-modal-content-email-row-1-2-left>input{
    width: 100%;
    outline: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 1.4vh 1vw;
    font-size: 1em;
}
.cpe-modal-content-email-row-1-2-right>input{
    width: 100%;
    outline: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 1.4vh 1vw;
    font-size: 1em;
}
.cpe-modal-content-email-row-1-2-right>div{
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 4px;
}
.cpe-modal-content-phone-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cpe-modal-content-phone-row-item{
    margin-top: 3.5vh;
    width: 48%;
}
.cpe-modal-content-phone-row-item-1{
        margin-bottom: 2vh;
}
.cpe-modal-content-phone-row-item-1>p{
    font-size: 1em;
    font-weight: 400;
}
.cpe-modal-content-phone-row-item-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cpe-modal-content-phone-row-item-2>input{
    width: 100%;
    outline: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 1.4vh 1vw;
    font-size: 1em;

}
.cpe-modal-content-phone-row-item-2>div{
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 4px;

}
.cpe-modal-content-address-row-item-heading{
    margin-top: 2vh;
    display: flex;
    align-items: center;
}
.cpe-modal-content-address-row-item-heading>div{
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 4px;

}
.cpe-modal-content-address-row-item-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cpe-modal-content-other{
    padding: 4.5vh 2.7vw;
    padding-top: 5vh;
    border-bottom: 1px solid #E5E7EB;
}
.cpe-modal-content-other-heading{
    display: flex;
    align-items: center;
    /* background-color: aqua; */
}
.cpe-modal-content-other-heading>h1{
    font-size: 1.3em;
    font-weight: 600;
    margin: 0.5vh 0px;
}
.cpe-modal-content-other-item{
    margin-top: 3vh;
    width: 48%;
}
.cpe-modal-content-other-item-label{
    margin-bottom: 2vh;
}
.cpe-modal-content-other-item-label>p{
    font-size: 1em;
}
.cpe-modal-content-other-item-input>input{
    width: 100%;
    outline: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 1.4vh 1vw;
    font-size: 1em;
}
.cpe-modal-content-other-item-input-div{
    width: 100%;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 1.4vh 1vw;
    display: flex;
    align-items: center;

}
.cpe-modal-content-other-item-input-dp{
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 2px 2px 10px 2px #cecbcb;
    width: 100%;
    min-height: 5vh;
    max-height: 35vh;
    overflow-y: scroll;
}
.cpe-modal-content-other-item-input-div>span{
    background-color: #E5E7EB;
    margin-right: 1vw;
    padding: 0.5vh 1vw;
}
.cpe-modal-content-other-item-input-dp>ul{
    padding: 0px;
    list-style: none;
}
.cpe-modal-content-other-item-input-dp>ul>li{
    padding: 2vh 1vw;
    display: flex;
    align-items: center;
}
.cpe-modal-content-other-item-input-dp>ul>li>input{
    margin-right: 10px;
}
.cpe-modal-content-other-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

}
.cpe-modal-content-footer{
    margin-top: 5vh;
    margin-bottom: 1vh;
    padding: 0px 2.7vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.cpe-modal-content-footer>button{
    padding: 1.8vh 1.5vw;
    margin-left: 1vw;
    border-radius: 4px;
    border: none;
    font-size: 1.1em;
    cursor: pointer;
    font-weight: 600;
}
