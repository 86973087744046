/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */

.dashboard{
    /* margin: 5% 0px;*/
    /* background-color: blueviolet;  */
    height: 100vh;
    /* font-family: 'Inter', sans-serif; */
     /* display: flex;
     flex-direction: row; */
}
.p{
    color: rgba(64, 64, 64, 1);
    font-size: 1.2em;
    font-weight: 400;
}
.p-head{
    color: rgba(64, 64, 64, 1);
    font-size: 1.4em;
    font-weight: 400;
}
.unsendable-child{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.unsendable-child-element{
    display: flex;
    flex-direction: column;
    width: 33.3%;
    height: 100%;
}
.child{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    padding: 2rem;
}

 
.grid-container{
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  margin-top: 3rem;
  /* border:1px solid rgba(229, 231, 235, 1); */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
    /* gap: 10px; */
  /* padding: 10px; */
  /* background-color: white;
  margin-top: 4rem ;
  border-radius: 10px 0px;
  border:1px solid rgba(229, 231, 235, 1); */
  
}
#contacts-heading{
    grid-column: 1/span 3;
    border:1px solid rgba(229, 231, 235, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color:rgb(32,129,226);
    font-weight: 600;
    padding:0.9em 1.7em;
    background-color: rgba(250, 250, 250, 1);
    font-size: 1.25em;
    text-align: left;
 
}
#contacts{
    grid-row: 2/span 2;
    border-bottom: 1px solid rgba(229, 231, 235, 1);
    border-right: 1px solid rgba(229, 231, 235, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.contacts-inner{
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: flex-start;
     gap:2vw;
     /* border:1px solid green; */
     width:70%;
    /* style={{padding:"1.9em 1.2em" ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center' , gap:"15px"}} */
}
.grid-container > div {
   
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border:1px solid rgba(229, 231, 235, 1); */
    border-bottom: 1px solid rgba(229, 231, 235, 1);
    border-right: 1px solid rgba(229, 231, 235, 1);
    text-align: center;
    /* font-size:2em; */
 
   
  }
  .text-one{
     font-weight: 600;
     font-size:1.25em;
     color:rgb(112,112,112);
     text-align: left;
     /* border:1px solid red; */
  
   
  }
  .text-two{
     font-weight: 600 ;
     font-size:1.875em;
     color:rgb(38,38,38);
     text-align: left;
     /* border:1px solid red; */
     cursor: pointer;
  }
  .text-two:hover,.text-2:hover{
    color:rgb(32,129,226);
    cursor: pointer;
  }
  .grid-items{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-item-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-center;
    padding:1.5vw 0vw;
    /* border: 1px solid red; */
    width:80%;
  }
  
  .text-1{
    font-weight: 400;
     font-size:1em;
     color:rgb(112,112,112);
     /* border:1px solid red; */
     text-align: left;
  
  }
  .text-2{
    margin-top: 0.4em;
    font-weight: 600 ;
    font-size:1.25em;
    color:rgb(38,38,38);
    /* border:1px solid red; */
    text-align: left;
    cursor: pointer;

  }

  .text-3{
      font-weight: 700;
      font-size: 1.5em;
      color:rgb(193,193,193);
  }
  .grid-item-container2{
    display: flex;
    justify-content: space-between;
    align-items: flex-center;
    padding:1.5vw 0vw;
    /* border: 1px solid red; */
    width:80%;
  }
  .grid-two-inner{
    /* background-color: red; */
    display: flex;
    width:100%;
    padding:0.8vw 0vw;
    justify-content: space-between;
    align-items: center;
  }
  .grid-container-3{
    display: grid;
   grid-template-columns: 50% 50% ;
   margin-top: 3rem;
  /* border:1px solid rgba(229, 231, 235, 1); */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  }
  .grid-container-3 > div {
   
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border:1px solid rgba(229, 231, 235, 1); */
    border-bottom: 1px solid rgba(229, 231, 235, 1);
    border-right: 1px solid rgba(229, 231, 235, 1);
    text-align: center;
    /* font-size:2em; */
 
   
  }
  #contacts-heading3{
    grid-column: 1/span 2;
    border:1px solid rgba(229, 231, 235, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color:rgb(32,129,226);
    font-weight: 600;
    padding:0.9em 1.7em;
    background-color: rgba(250, 250, 250, 1);
    font-size: 1.25em;
    text-align: left;
  }
 
  .dropdown-options{
    position: absolute;
  top:2.8em;
  left: 0;
  width: 100%;
  color:rgb(122,122,122);
  font-size: 1.1em;
  font-weight: 300;
  background-color: #fff;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  /* border-top: none; */
  z-index: 100;
  }

  .dropdown-option {
    padding: 0.56em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }
  .spinner {
     
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }