
.homesidenavbar{
    background-color: #FFFFFF;
    width: 18%;
    padding: 30px 0px;
    height: 100%;
}
.homesidenavbar-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px 0px 10px 0px; */
    /* padding: 0 auto; */
    /* margin-top: 10px; */
    /* background-color: red; */
    padding-bottom: 0.8em;

}
.logo-same{
    max-width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-same-img{
    height: auto;
    max-width: 100%;
    /* background-color: red; */
}
img{
    height: 1rem;
}
.homesidenavbar-spacer{
    height: 20px;
    margin: 10px 0px;
}
.homesidenavbar-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
}
.homesidenavbar-item{
    margin: 10px 0px;
    /* padding: 15px 40px; */
    display: flex;
    align-items: center;
    cursor: pointer;
   
}
#homesidenavbar-item-link{
    width: 100%;
    padding: 13px 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    /* background-color: red; */
    /* color: #404146; */

}
#homesidenavbar-item-link>p{
    margin-left: 10px;
    /* color: #404146; */
    font-size: 1.025em;
    letter-spacing: 0.7px;
    font-weight: 600;
}
.homesidenavbar-item>a{
    color: #404146;
}

.homesidenavbar-item>a.active{
    color: #2081E3;
    border-right: 2px solid #2081E3 ; 
}
