.dropdown{
    background-color: white;
    padding: 0.3rem;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    margin: 2px;
    border-radius: 4px;
    z-index: 999 !important;
    max-height: 200px;
    overflow: auto;
    width: 21.8rem !important;
}
.dropdown::-webkit-scrollbar {
    display: none;
  }
.dropDownBtn{
    width: 22rem;
    height: 2.1rem;
    border:1px solid rgb(223, 223, 223);
    background-color: white;
    border-radius: 3px;
    color: rgb(206, 206, 206);
    cursor: pointer;
}

.dropDownBtn::-webkit-scrollbar{
    display: none;
}
.refresh:active{
    /* background-color: rgb(255, 255, 255) !important; */
    outline: 1px solid rgb(0, 0, 0);
}
.clear:active{
    /* background-color: rgb(255, 255, 255) !important; */
    outline: 1px solid rgb(0, 0, 0);
}
.filter-component{
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(236, 236, 236);
    padding: 1.5rem 1.3rem;
}

