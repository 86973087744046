/* .account-navbar{
    background-color: aqua;
    
} */
.account-navbar>ul{
    display: flex;
    align-items: center;
    padding: 0px;
    list-style: none;
}
.account-navbar>ul>li{
    font-size: 1.06em;
    margin-right: 4vw;
    /* padding: 2vh 0px; */
    cursor: pointer;
}
.account-navbar>ul>li>a{
    padding: 2vh 0px;
    color: #635f5f;
    text-decoration: none;
}
.account-navbar>ul>li>a:hover{
    color: #262626;
    border-bottom: 0.5px solid #8f8b8b;
}

.account-navbar>ul>li>a.active{
    color: #262626;
    border-bottom: 2px solid #2081E3;
    padding: 2vh 0px;
}