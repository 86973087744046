/* .reset-password{
    background-color: aqua;
} */

.login{
    background-color: #ffffff;
    max-height: 100vh;
    /* height:100%; */
    overflow: hidden;
    /* width: 100vw; */
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.login-content{
    width: 45%;
    /* background-color: aqua; */
    /* padding: 2.5em 2em; */
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.login-content-logo{
    align-self: flex-start;
    padding: 1em 2em;
    /* background-color: pink; */
}
.login-content-logo>img{
    max-width: 100%;
    max-height: 100vh;
}
.reset-password>h1{
    font-size: 2.6em;
    
    font-weight: 500;
    /* line-height: 2.5em; */
    color: #000000;
}
.reset-password>p{
    /* line-height: 1.2em; */
    margin: 1em 0px;
    font-size: 1em;
    color: #404040;
}
.reset-password-content{
    margin: 3em 0px;
    padding: 0;
    border-radius: 10px;
    background-color: #ffffff;
}
.reset-password-content-1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}
.reset-password-content-row{
    margin: 1.5em 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.reset-password-content-row>label{
    /* background-color: aquamarine; */
    color: #262626;
    font-size: 1.1em !important;
    font-weight: 400;
    margin-bottom: 0.7em;
}
.reset-password-content-row-input{
    position: relative;
    /* background-color: aqua; */
}
.reset-password-content-row-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 10px;
    color: #404040;
    cursor: pointer;
}
.reset-password-content-row-input>input{
    width: 100%;
    padding: 1em;
    outline: none;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    color: #262626;
}
.reset-password-content-row-input>input:focus{
    border: 1px solid #2181E2;
}
.reset-password-content-2{
    margin: 1.5em 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.reset-password-content-2>button{
    /* background-color: aqua; */
    font-weight:550;
    font-size:14px;
    padding:0.8em 1.3em;
    color:white;
    margin-left: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    
}