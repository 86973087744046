.rename-modal{
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rename-modal-content{
    width: 30%;
    
    
}
.rename-modal-content-close{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.rename-modal-content-close>div{
    border-radius: 100%;
    padding: 1.3vh  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    color: white;
}




.rename-modal-content-content{

    background-color: white;
    border-radius: 4px;
    padding:3vw 2.4vw 3vw 2.4vw;
}
.rename-modal-content-content-1>h3{
    font-weight: 600;
    color: #262626;
    font-size: 1.22em;
    line-height: 1.4em;
    letter-spacing: 0.7px;
}
.rename-modal-content-content-2{
    margin: 4vh 0px;
    position: relative;
}
.rename-modal-content-content-2>input{
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    font-size: 0.9em;
    color: #746969;
}
.rename-modal-content-content-2>input:focus{
    border: 1px solid black;

}
.rename-modal-content-content-3{
    display: flex;
    justify-content: flex-end;
    padding: 0px;
}
.rename-modal-content-content-3>button{
    padding: 1.6vh 3vh;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    /* color: #b1a2a2; */
}
