.splitmodal{
    /* background-color: aquamarine; */
    /* background-color: rgba(148, 139, 139, 0.781); */
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.splitmodal-content{
    width: 30%;
    
    
}
.splitmodal-content-close{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    /* border-radius: 100%; */
    cursor: pointer;
}
.splitmodal-content-close>div{
    border-radius: 100%;
    padding: 1.3vh  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    color: white;
}
.splitmodal-content-content{

    background-color: white;
    border-radius: 4px;
    /* padding: 5px 10px; */
    /* background-color: blueviolet; */
    padding: 2vw 3vw;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    /* align-items: center; */
}
.splitmodal-content-content-1>h3{
    font-weight: 600;
    color: #262626;
    font-size: 1.2em;
    /* margin: 5vh 0px; */
    margin-top: 3vh;
    margin-bottom: 5vh;
}
.splitmodal-content-content-2{
    margin: 1.5em 0px;
    position: relative;
    /* background-color: aqua; */
}
.splitmodal-content-content-2>p{
    font-size: 1em;
    margin: 1vh 0px;
}
.splitmodal-content-content-2-1{
    background-color: #ffffff;
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    /* overflow: auto;0 */
}

.splitmodal-content-content-2-1>span{
    font-size: 0.7em;
    background-color: #E5E7EB;
    padding: 5px;
    margin: 2px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #404040;
    
}
.splitmodal-content-content-2-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.splitmodal-content-content-2-2>button{
    padding: 10px 15px;
    border: none;
    background-color: #2081E2;
    font-size: 0.9em;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;


}
.splitmodal-content-content-2-2>input{
    width: 100%;
    padding: 1.5vh 1vw;
    outline: none;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    font-size: 1em;
    color: #746969;
}
.splitmodal-content-content-3{
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
}
.splitmodal-content-content-3>button{
    padding: 2vh 3vh;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1.05em;
    font-weight: 600;
    /* color: #b1a2a2; */
}