.addsegmentmodal{
    /* background-color: aquamarine; */
    background-color: rgba(148, 139, 139, 0.781);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addsegmentmodal-content{
    width: 50%;
    
    
}
.addsegmentmodal-content-close{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.addsegmentmodal-content-close>p{
    padding: 10px ;
    border-radius: 100%;
    background-color: grey;
    color: white;
}
.addsegmentmodal-content-content{

    background-color: white;
    border-radius: 4px;
    /* padding: 5px 10px; */
    /* background-color: blueviolet; */
    padding: 2vw;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    /* align-items: center; */
}
.addsegmentmodal-content-content-1>h3{
    font-weight: 600;
    color: #262626;
    font-size: 1.2em;
}
/* .addsegmentmodal-content-content-2>p{
    font-weight: 500;
    margin: 1vh 0vh;
    color: #404040;
    
} */
.addsegmentmodal-content-content-2>input{
    width: 100%;
    margin: 2em 0px;
    padding: 10px 15px;
    outline: none;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    font-size: 0.9em;
    color: #746969;
}
.addsegmentmodal-content-content-3{
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
}
.addsegmentmodal-content-content-3>button{
    padding: 2vh 3vh;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 600;
    /* color: #b1a2a2; */
}

/* .addsegmentmodal-content-content>input{
    width: calc(100% - 16%);
    padding: 20px 10px;
    border: 2px solid grey;
    border-radius: 4px;
    font-size: 1.2vw;
}
.addsegmentmodal-content-content-btn{
    padding: 2px;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

} */