
.import-md-heading{
    margin: 1em 0px;
}
.import-md-heading>h1{
    font-size: 2.2em;
    font-weight: 500;
}



.import-md-content{
    margin: 5vh 0px;
}
.uploadmd{
    background-color: #ffffff;
    padding: 5vh 2.2vw;
    border-radius: 4px;
}

.uploadmd-heading>h1{
    color: rgb(44, 44, 44);
    padding-bottom: 10px;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.8em;
}
.uploadmd-desc>p{
    color: #6b6b6b;
    line-height: 1.4em;
}
/* .uploadcsv-2{
    

} */
.uploadmd-2>h1{
    color: rgb(44, 44, 44);
    padding-bottom: 10px;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.8em;

}
.uploadmd-2>div{
    position: relative;
}
.uploadmd-2>div>p{
    color: #000000;
    line-height: 1.5em;
    font-size: 0.85em;
}
.uploadmd-2>div>input{
    margin: 1vh 0px;
    width: 100%;
    padding: 1.5vh 1vw;
    border-radius: 4px;
    font-size: 0.85em;
    border: 1px solid #EBEDF0;
    outline: none;
    color: #262626;
}
.uploadmd-2>div>input:focus{
    border: 1px solid #2081E2;
}
.uploadmd-2>div>div>ul>li:hover{
    background-color: #e4dfdf;
    cursor: pointer;
}
.uploadmd-2-1{
    color: rgb(59, 59, 59);
    font-size: 0.9em;
    /* background-color: #2081E2; */
    padding: 3em 0px;
    border-radius: 4px;
    margin: 4vh 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: dashed 1px rgb(221, 221, 221);
    cursor: pointer;
    background-color: rgba(250, 250, 250, 1);
}
.uploadmd-2-1:hover{
    border: 1px dashed rgba(32, 129, 226, 1) !important;
    background-color: rgba(32, 129, 226, 0.1) !important;
}
.uploadmd-2-1-2{
    padding: 2.5em 0px;
    border-radius: 4px;
    margin: 4vh 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgba(32, 129, 226, 1);
    background-color: rgba(32, 129, 226, 0.1);
    cursor: pointer;
}
.uploadmd-2-1-1-upload-icon{
    
     /* background-color: aqua; */
   /* display: flex;
    justify-content: center;
    align-items: center; */
}
.uploadmd-2-1-1-upload-icon-ct{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.uploadmd-2-1-1-upload-icon-ct-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh 0px;
}
.uploadmd-2-1-1-upload-icon-ct>div{
    padding: 0.6em;
    border-radius: 4px;
    /* background-color: aqua; */
}
.uploadmd-2-1-1-upload-icon-ct>div>input{
    display: none;
}
.uploadmd-2-1-1-upload-icon-ct>h3{
    color: #262626;
    line-height: 16.94px;
    margin: 1vh 0px ;
    margin-top: 3vh;
    font-size: 0.9em;
    /* #404040 */
}
.uploadmd-2-1-1-upload-icon-ct>p{
    color: #404040 ;
    font-size: 0.8em;
}
.uploadmd-2-1-1>h3{
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    margin: 0.5em 0px;
}
.uploadmd-2-1-1>p{
    font-size: 0.7em;
    text-align: center;
    font-weight: 400;
}
.uploadmd-2-2{
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.uploadmd-2-2>button{
    font-weight: 600;
    padding: 1em;
    border: none;
    background-color: #2081E2 ;
    color: #ffffff;
    border-radius: 4px;
    font-size: 0.9em;
    cursor: pointer;
}
.uploadmd-2-1-progress-bar{
    margin:4vh 0px;
    padding: 3em 2vw;
    background-color: #f8f6f68e;
    border-radius: 4px;
    border: dashed 1px rgb(221, 221, 221);
}