.generalconfirmmodal{
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.generalconfirmmodal-content{
    width: 30%;
    
    
}
.generalconfirmmodal-content-clos{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.generalconfirmmodal-content-clos>div{
    border-radius: 100%;
    padding: 1.3vh  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    color: white;
}
.generalconfirmmodal-content-clos>p{
    padding: 10px ;
    border-radius: 100%;
    background-color: grey;
    color: white;
}
.generalconfirmmodal-content-content{

    background-color: white;
    border-radius: 4px;
    padding: 6vh 2vw;
}
.generalconfirmmodal-content-content-1>h3{
    font-weight: 600;
    color: #262626;
    font-size: 1.25em;
    line-height: 1.5em;
}
.generalconfirmmodal-content-content-2>p{
    font-weight: 600;
    margin: 1em 0vh;
    color: #989898;
    font-size: 1em;
    line-height: 1.2em;
}
.generalconfirmmodal-content-content-3{
    display: flex;
    justify-content: flex-end;
    padding-top: 3vh;
    /* background-color: aqua;  */
}
.generalconfirmmodal-content-content-3>button{
    padding: 2vh 3vh;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1.05em;
    font-weight: 600;
}
