.edit-account-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* background-color: aquamarine; */
}
.edit-account-heading > h2 {
  font-size: 36px;
}

.edit-account-route {
  margin: 2vh 0px;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
}
.edit-account-route > p {
  font-size: 0.8em;
  font-weight: 400;
  /* color: #404040E5; */
}
.edit-account-content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2vw 2vw;
}
.edit-account-content-heading {
  color: #262626;
  font-size: 0.8em;
}
.edit-account-content-content {
  /* background-color: aquamarine; */
  margin-top: 20px;
}

.edit-account-content-content-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  padding: 3vh 0px;
}
.edit-account-content-content-row-left {
  color: #404040;
  width: 50%;
}
.edit-account-content-content-row-left > p {
  font-weight: 400;
  font-size: 1em;
}
.edit-account-content-content-row-right {
  color: #262626;
  width: 50%;
}
.edit-account-content-content-row-right > p {
  font-weight: 500;
  font-size: 1em;
}

.edit-account-content-content-footer {
  margin-top: 2vh;
  padding: 1vh 0px;
  display: flex;
  align-items: center;
  /* background-color: aquamarine; */
}
.edit-account-content-content-footer-left {
  width: 50%;
}
.edit-account-content-content-footer-left > p {
  font-weight: 400;
  font-size: 1em;
  color: #404040;
}
.edit-account-content-content-footer-right {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bad-background {
  background-color: #ef44441a;
  padding: 5px 2vw;
  border-radius: 50px;
}
.bad-background > p {
  font-size: 0.8em;
  color: red;
}
.good-background {
    background-color: #66ef441a;
    padding: 5px 2vw;
    border-radius: 50px;
}
.good-background {
    font-size: 0.8em;
    color: rgb(0, 165, 74);
}
.edit-account-content-content-footer-right-2 {
  background-color: #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  padding: 5px 10px;
  border-radius: 6px;
  color: #262626;
  cursor: pointer;
}
/* .edit-account-content-content-row-right{
  white-space: warp;
} */