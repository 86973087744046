.searchmodal{
    background-color: rgba(0,0,0,0.7);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchmodal-content{
    width: 60%;
}
.searchmodal-content-close{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    /* border-radius: 100%; */
    cursor: pointer;
}
.searchmodal-content-close>div{
    border-radius: 100%;
    padding: 1.3vh  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    color: white;
}
.searchmodal-content-content{

    background-color: white;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}
.searchmodal-content-content>input{
    width: calc(100% - 16%);
    padding: 35px 10px;
    border: 2px solid grey;
    border-radius: 4px;
    font-size: 1.2vw;
}
.searchmodal-content-content-btn{
    padding: 2px;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.searchmodal-content-content-btn>p{
    margin-left: 1vw;
}