.add-modal{
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-modal-content{
    width: 30%;
    
    
}
.add-modal-content-close{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    /* background-color: red; */
}
.add-modal-content-close>div{
    /* padding: 10px ; */
    border-radius: 50%;
    height: 35px;
    width:35px;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    font-weight: 500;
    align-items: center;
    background-color: rgb(64, 64, 64);
    color: white;
    text-align: center;
}
.add-modal-content-content{

    background-color: white;
    border-radius: 4px;
    padding:3vw 2.4vw 3vw 2.4vw;
}
.add-modal-content-content-1>h3{
    font-weight: 600;
    color: #262626;
    font-size: 1.22em;
    line-height: 1.4em;
    letter-spacing: 0.7px;
}
.add-modal-content-content-2{
    margin: 4vh 0px;
    position: relative;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
}
.add-modal-content-content-2>span{
    background-color: #E5E7EB;
    padding: 0.7vh 1vw;
    font-size: 0.8em;
    margin-right: 1vw;
    display: flex;
    align-items: center;
}
.add-modal-content-content-2>input{
    width: 100%;
    padding: 10px 0px;
    outline: none;
    border: none;
    /* border: 1px solid #BFBFBF; */
    /* border-radius: 4px; */
    font-size: 0.9em;
    color: #746969;
}
.add-modal-content-content-2-dp{
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    left: 0px;
    top: 6vh;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 2px 2px 5px 2px #c9c0c0;
    height: 15vh;
    overflow-y: auto;
}
.add-modal-content-content-2-dp>ul{
    list-style: none;
    padding: 0px;
}
.add-modal-content-content-2-dp>ul>li{
    padding: 1.5vh 1vw;
    display: flex;
    align-items: center;
}
/* .add-modal-content-content-2>input:focus{
    border: 1px solid black;

} */
.add-modal-content-content-3{
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
}
.add-modal-content-content-3>button{
    padding: 1.6vh 3vh;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    /* color: #b1a2a2; */
}
