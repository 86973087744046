.dashboard-container{
    height: 100vh;
     display: flex;
     flex-direction: row;

}
.dashboard-container-left{
    background-color: #FFFFFF;
    width: 22%;
    padding: 30px 0px;
    height: 100%;
    /* overflow-y: scroll; */
    /* box-shadow: -5px 5px 10px 10px black; */
    /* background-color: red; */
}
.dashboard-container-right{
    background-color: #F3F4F6;
    width: 82%;
    padding: 25px 30px;
    height: 100%;
    overflow-y: scroll;
}
.dashboard-container-left-logo{
    /* background-color: red; */
    padding: 10px 40px;
    margin-top: 10px;
    margin-bottom: 55px;

}
.dashboard-container-left-item{
    /* background-color: brown; */
    margin: 10px 0px;
    display: flex;
    align-items: center;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
   
    /* border-right: 2px solid #4DC36B; */
}
.dashboard-container-left-item>p{
    
    margin-left: 10px;
    color: #404146;
    /* font-size: 14px; */

    font-size: 1rem;

    font-weight: 400;

}
.dashboard-left-spacer{
    height: 20px;
    /* background-color: #4DC36B; */
    margin: 10px 0px;
}
.dashboard-container-left-bottom{
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
}
@media only screen and (max-width : 1000px){
    /* .dashboard-container-left{
         position: fixed;
         bottom: 0px;
         width: 100%;
    padding: 0px 0px;
    height: 150px;
    display: flex;
    flex-direction: row;
    z-index: 1;
    border: 1px solid black;
    }
    .dashboard-left-spacer{
        display: none;
    }
    */


    .dashboard-container-right{
        width: 100%;

    } 
    .dashboard-container-left{
        display: none;
    }

}

