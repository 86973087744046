.auto-activities-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.auto-activities-heading-btn{
    cursor: pointer;
    padding: 10px 20px;
    background-color: #2081E3;
    border-radius: 4px;
    color: #ffffff;
}

.auto-activities-content{
    padding: 4vh 0vw;
    background-color: #ffffff;
    border-radius: 10px;
    /* border: 1px solid black; */
}
.auto-activities-content-heading>h1{
    font-size: 1.5em;
    margin: 2vh 2.5vw;
    margin-bottom: 4vh;
    /* padding: 2vh 0vw; */
    /* background-color: aquamarine; */

}
/* .auto-activities-content-sync::-webkit-scrollbar{
    display: none;
} */

.sync-table-header-activity{
    display:flex;
    /* justify-content: center; */
    align-items: center;
    /* background-color: #2081E3; */

}
.sync-table-header-activity>p{
    margin-right:8px;
}
.sync-table-header-activity-icon{
    display:"flex";
    justify-content:"center";
    align-items:"center";
    
}

.e-filtermenudiv e-icons e-icon-filter{
    color: green;
    background-color: red !important;
}