.import-status-route{
    margin: 1.25em 0px;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}
.import-status-route>p{
    font-size: 0.8em;
    font-weight: 400;
}
.import-status-heading{
    margin: 1em 0px;
}
.import-status-heading>h1{
    font-weight: 600;
    font-size: 2.2em;
}



.import-status-content{
    margin: 5vh 0px;
}
.import-status-content-1{
    background-color: #ffffff;
    padding: 5vh ;
    border-radius: 4px;
}
.import-status-content-1>div>h3{
    /* font-size: clamp(0.9rem,1.3vw,20px); */
    /* background-color: aqua; */
    font-weight: 600;
    /* font-size: clamp(0.5rem,1.7vw,25px);
     */
     font-size: 1.3em;
    color: #262626;
    /* background-color: #2081E2; */
}
.import-status-content-1-error{
    display: flex;
    align-items: center;
    background-color: #E9F3FD;
    border-radius: 4px;
    padding: 1.5vh 2vh;
    /* margin: 20px 0px; */
    margin-top: 5vh;
}
.import-status-content-1-error>p{
    font-size: clamp(0.5rem,1vw,0.9rem);
    margin-left: 10px;
    /* color: #2081E2; */
    /* color: rgb(221, 243, 221); */
}
.import-status-content-2{
    margin: 6vh 0px;
    background-color: #ffffff;
    padding: 0vh 5px ;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.import-status-content-2-1{
    padding: 5vh 5vh;
    width: 33%;
    /* background-color: #2081E2; */
}
.import-status-content-2-1>p{
    color: #262626;
    font-size: 14px;
    font-weight: 400;
}
.import-status-content-2-1>h2{
    color: #262626;
    font-size: 30px;
    margin: 6px 0px;
}
.import-status-content-3{
    background-color: #ffffff;
    padding: 5vh 0px ;
    border-radius: 4px;
    font-size: 0.9em;
}
.import-status-content-3>h1{
    color: #262626;
    font-size: 20px;
    font-weight: 700;
    padding: 0px 2.3vw;
}
/* .import-status-content-3-nav{
    background-color: aqua;
} */
.import-status-content-3-nav>ul{
    list-style: none;
    padding: 0px;
    padding: 0px 2.3vw;
    margin: 0px;
    margin-top: 4vh;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E7EB;
}
.import-status-content-3-nav>ul>li{
    font-size: clamp(0.5rem,2vw,14px);
    padding: 2vh 0vw;
    /* background-color: aqua; */
    border-bottom: 2px solid #2081E2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.import-status-content-3-nav>ul>li>div{
    padding: 0.7vh 1.3vh;
    margin-left: 10px;
    background-color: #E5E7EB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}
.import-status-content-3-error-heading{
    padding: 4vh 2.3vw;
    border-bottom: 0.5px solid #E5E7EB;
    border-top: 0.5px solid #E5E7EB;
    /* background-color: #2081E2; */
}
.import-status-content-3-error-heading>h3{
    font-size: 14px;
    font-weight: 400;
    color: #9C9C9C;
}
/* .import-status-content-3-record{
    

} */
.import-status-content-3-record-heading{
    padding: 2vh 2.3vw;
    /* background-color: aqua; */

}
.import-status-content-3-record-heading>p{
    font-size: 1.2em;
    color: #1F1021;
}
.import-status-content-3-record-data{
    /* padding: 0px 2.3vw; */
    border-bottom: 0.5px solid #E5E7EB;
    border-top: 0.5px solid #E5E7EB;
    
}
.import-status-content-3-record-data-content{
    padding: 3vh 2.3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #E9F3FD;
    color: #262626;
    font-size: 1.2em;
    /* background-color: #2081E2; */

}
.import-status-content-3-record-data-right{
    display: flex;
    justify-content: center;
    align-items: center;
}


.import-status-content-3-footer{
    padding: 4vh 2.3vw;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.import-status-content-3-footer>p{
    font-size: 1.2em;
    color: #262626;
}
.import-status-content-3-footer>button{
    padding: 1.5vh 1.5vw;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #E5E7EB;
    color: #404040;
    font-size: 1.2em;
    font-weight: 530;
}