.group{
    /* background-color: red; */
    padding: 10px 0px;
    
}
.group-no-data{
    height: 50vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 110px 267px; */
}
.group-no-data>p{
    font-size: 1em;
    font-weight: 400;
    margin: 10px 0px;
   color:#262626;
  
}
.group-no-data>button{
    margin-top: 16px;
    padding: 12px 22px;
    background-color: #2081E3;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    
}
.group-content{
    border-radius: 10px;
    border:1px solid #E5E7EB;
    background-color: #ffffff;
}
.group-content-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 2vw;
}
.group-content-1-1{
    display: flex;
    /* background-color: #4DC36B; */
    align-items: center;
}
/* .group-content-1-1>input{
    background-color: red;
 
} */
.group-content-1-1 p{
  font-weight: 600;
  color:rgb(65,65,65);
  font-size: 0.9em;
  letter-spacing: 0.4px;
}
.group-content-1-2-1{
    display: flex;
}
.group-content-1-2-1>input{
    padding: 10px;
    outline: none;
    /* border-radius: 4px;  */
    border: 1px solid rgb(229,231,235);
    color:  #262626;
    border-radius: 4px;
    /* border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; */
}
.group-content-1-2-1>input:focus{
    /* border: 1px solid #c1bcbc;  */
    border: 1px solid #E5E7EB;
}
.group-content-1-2-1>div{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5E7EB;
    padding: 0px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.group-content-1-2-2{
    width: 12vw;
    border: 1px solid #E5E7EB;
    /* background-color: #4DC36B; */
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    position: relative;
}
.group-content-1-2-2>p{
    color: #4C4E4C;
}
.group-content-1-2-2>div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.group-content-1-2-2-dropdown{
    z-index: 1;
    width: 100%;
    right: 0px;
    position: absolute;
    top: 40px;
    /* box-shadow: ; */
    box-shadow: 1px 3px 8px 2px #d8d8d8;
    /* background-color: aqua; */
    

    /* zIndex:1,width:"100%",right:"0px",position:"absolute",top:"40px",backgroundColor:"red" */
}
.group-content-1-2-2-dropdown>ul{
    width: 100%;
    padding: 0px;
    list-style: none;
    background-color: #ffffff;
    /* background-color: #4DC36B; */
    box-shadow: 1px 3px 13px 2px #c1bcbc,
}
.group-content-1-2-2-dropdown>ul>li{
    padding: 10px 10px;
    font-size: 0.95em;
    font-weight: 500;
    color: rgb(122,122,122);
   
}
.group-content-1-2-2-dropdown>ul>li:hover{
    background-color: #f0eded;
    cursor: pointer;
}
.group-content-1-2-3{
    background-color: #E5E7EB;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}
.group-content-1-2{
    width: 35vw;
    display: flex;
    /* background-color: #4DC36B; */
    justify-content: space-between;
}
.group-content-3{
    border-top: 1px solid #E5E7EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 2vw;
}
.group-content-3-2>div>p{
    font-size: 0.8em;
    /* width: 20vw; */
    /* background-color: #4DC36B; */
}
.group-content-3-2>button{
    margin-left: 10px;
    padding: 10px 15px;
    /* background-color: #F2F2F5; */
    border: none;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: 500;
    color: #404040;

}
.group-heading{
    font-size: 1.3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.group-heading-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.group-heading-right-2{
    cursor: pointer;
    padding: 10px 20px;
    background-color: #2081E3;
    border-radius: 4px;
    color: #ffffff;
}

.group-content-3-1>p{
    font-weight: 400;
    font-size:0.875em;
    color:rgba(64, 64, 64, 1)
}
.group-content-3-1>p>span{
    font-weight: 600;
    /* font-size:1em;
    color: #404040; */
}