.groupcomponent{
    padding: 1.5vw 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E5E7EB;
    transition: background-color 0.4s;
}
.groupcomponent:hover{
    background-color: #f5f6f8a1;
}
#group-abbr{
    border-bottom: 1px dashed #5D5D5D;
}
.group-abbr-span{
    display: none;

}
#group-abbr:hover>span{
    /* background-color: aqua; */
    display:inline
}
.groupcomponent-left{
    display: flex;
}
.groupcomponent-left-2>h3{
    font-size: 1.287em;
    font-weight: 500;
    margin-bottom: 5px;
}
.groupcomponent-left-2>p{
    font-size: 0.95em;
    font-weight: 400;
    margin-bottom: 5px;
    color: #404040E5;
}
.groupcomponent-left-2>button{
    margin: 5px 0px;
    padding: 11px 18px;
    background-color: #E5E7EB;
    border: 1px solid #E5E7EB;
    /* border: none; */
    color:#404040;
    border-radius: 4px;
    font-size: 0.95em;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.4s, color 0.4s;
    letter-spacing: 0.7px;
}
.groupcomponent-left-2>button:hover{
    background-color: #ffffff;
    color: #4C4E4C;
    border: 1px solid #E5E7EB;
}
.groupcomponent-right{
    width: 23vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.groupcomponent-right-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-left: 2px solid black; */
    padding-left: 1vw;
    margin-bottom: 10px;
}
.groupcomponent-right-1-1>p{
    font-size: 1em;
    letter-spacing: 0.2px;
    color: #4F4846;
    font-weight: 300;
}
.groupcomponent-right-1-2{
    /* background-color: aqua; */
    width: 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.groupcomponent-right-1-2>div{
    width: 4.8vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E5E7EB;
    /* background-color: aquamarine; */
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}
.groupcomponent-right-1-2>div>button{
    border: none;
    background-color: #E5E7EB;
    font-size: 0.8em;
    color:#4C4E4C;
    font-weight: 500;
    cursor: pointer;
    
}
.more-dropdown-div{
    background-color: #ffffff;
    width: 150%;
    position: absolute;
    top: 29px;
    left: -50%;
    border-radius: 4px;
    border:1px solid #E5E7EB;
    /* box-shadow: 1px 3px 13px 2px #c1bcbc; */
    box-shadow: 0px 8px 20px 0px #0000001A;

    z-index: 1;
}
.more-dropdown-div>ul{
    padding: 0px;
    list-style: none;
}
.more-dropdown-div>ul>li{
    padding: 10px 10px;
    font-size: 0.98em;
    color: rgb(119, 119, 119);
    display: flex;
    align-items: center;
}
.more-dropdown-div>ul>li:hover{
    background-color: none;
    cursor: pointer;

}