
.contact-profile-1{
    border: 1px solid rgb(235, 235, 235);
    margin-top: 8vh !important;
    padding: 0px ;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contact-profile-1-1{
    width: 33%;
    padding: 4.5vh 3.2vw;
}
.contact-profile-1-1>p,.contact-profile-1-2-1>p{
    font-size: 1.02em;
    color: #404040;
    font-weight: 500;
    line-height: 1.2;
}
.contact-profile-1-1>h1{
    margin-top: 1.3vh;
    color: #262626;
    font-size: 1.7em;
    font-weight: 700;
    /* line-height: 2; */
}
.contact-profile-1-2{
    width: 33%;
    padding: 4.5vh 3.2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: ; */
    /* background-color: aquamarine; */
}
.contact-profile-1-2-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact-profile-1-2-2{
    padding: 2vh 0px;
}
.contact-profile-1-2-2>#file{
    width: 100%;

}
.contact-profile-1-2-1>h1{
    color: #262626;
    font-size: 1.3em;
    font-weight: 700;
}
.contact-profile-2{
    border: 1px solid rgb(235, 235, 235);
    padding: 4.5vh 2.5vw;
    margin-top: 4vh;
    background-color: #ffffff;
    border-radius: 10px;
}
.contact-profile-2-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
    
}
.contact-profile-2-heading>h1{
    color: #262626;
    font-size: 1.3em;
    font-weight: 700;
}
.contact-profile-2-heading>button{
    padding: 1.5vh 1.5vw;
    background-color: #E5E7EB;
    color: #404040;
    font-size: 1.1em;
    border: none;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
}
.contact-profile-2-item{
    display: flex;
    align-items: flex-start;
    
    border-bottom: 1px solid #E5E7EB;
    padding: 2vh 0px;
}
.contact-profile-2-item-1{
    width: 55%;
}
.contact-profile-2-item-1>p{
    font-size: 1.01em;
    color: #404040;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.contact-profile-2-item-2>p{
    
    font-size: 1.01em;
    color: #262626;
    font-weight: 500;
}
.contact-profile-3{
    padding: 4.5vh 2.5vw;
    margin-top: 4vh;
    background-color: #ffffff;
    border-radius: 10px;
}
.contact-profile-3-heading{
    margin-bottom: 4vh;
}
.contact-profile-3-heading>h1{
    color: #262626;
    font-size: 1.3em;
    font-weight: 700;
}
.contact-profile-3-date{
    padding: 1vh 0px;
}
.contact-profile-3-date>p{
    color: #262626;
    font-size: 1.03em;
    font-weight: 500;
}
.contact-profile-3-item{
    margin: 1vh 0px;
    display: flex;
    /* align-items: center; */
}
.contact-profile-3-item-left{
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    margin-right: 0.8vw;
}
.contact-profile-3-item-left>div{
    padding: 0.5vh;
    background-color: #F98D3E;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .contact-profile-3-item-right{

} */
.contact-profile-3-item-right>h3{
    font-size: 1em;
    color: #262626;
    margin-bottom: 1vh;
    font-weight: 580;
}
.contact-profile-3-item-right>p{
    font-size: 0.8em;
    color: #999797;
}
.contact-profile-2-item-span{
    background-color: #F3F4F6;
    color: #262626;
    padding: 0.7vh 0.8vw;
    margin: 0px 0.7vw;
    border-radius: 4px;
}