.match-columns-navbar>ul{
    list-style: none;
    padding: 0px;
    display: flex;
    align-items: center;
}
.match-columns-navbar>ul>li{
    font-size: 0.95em;
    font-weight: 400;
    margin-right: 1.5em;
    padding: 1.5% 1%;
    cursor: pointer;
}
.match-columns-navbar>ul>li>a{
    padding: 2vh 0px;
    color: #635f5f;
    text-decoration: none;
}
.match-columns-navbar>ul>li>a:hover{
    color: #262626;
    border-bottom: 0.5px solid #8f8b8b;
}
.match-columns-navbar>ul>li>a.active{
    color: #262626;
    border-bottom: 2px solid #2081E3;
    padding: 2vh 0px;
}