/* .dashboard-container-right-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}
.dashboard-container-right-header-left{
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#dashboard-container-right-header-left-content-1{
    font-size: 13px;
    font-weight: 500;
}
#dashboard-container-right-header-left-content-2{
    font-size: 12px;
    font-weight: 200;
}
.dashboard-container-right-header-right{
    width: 32%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.flex-row{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #E7E8E9;
    border-radius: 4px;
}
.flex-row-1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background-color: #ED4D7A;
    border-radius: 4px;
}
.flex-row-1>p{
    color: #ffffff;
}
.dashboard-container-right-header-right-content{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-end;

}
#dashboard-container-right-header-right-content-1{
    font-size: 12px;
    font-weight: 600;
}
#dashboard-container-right-header-right-content-2{
    font-size: 12px;
    font-weight: 300;
} */
.subscribe-heading{
    /* font-size: 1.3em; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subscribe-heading-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subscribe-heading-right-1{
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #E5E7EB;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.subscribe-heading-right-1>p{
    font-size: 16px;
    color: #2081E3;
}
.subscribe-heading-right-2{
    cursor: pointer;
    padding: 10px 20px;
    background-color: #2081E3;
    border-radius: 4px;
    color: #ffffff;
}
.subscribe-heading-right-2>p{
    font-size: 0.9em;
    font-weight:600;
}
/* .subscriber-tab{
} */
.subscriber-tab>ul{
    padding: 0px;
    list-style: none;
    display: flex;
}
.subscriber-tab>ul>li{
    padding: 15px 0px;
    padding-left: 0px;
    margin-right: 35px;
    cursor: pointer;
    
}
