.ems-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ems-heading-btn {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #2081e3;
  border-radius: 4px;
  color: #ffffff;
}
.ems-content {
  padding: 4vh 0vw;
  background-color: #ffffff;
  border-radius: 10px;
  /* border: 1px solid black; */
}

.bad-background {
  background-color: #ef44441a;
  border-radius: 50px;
  height: 1.4rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bad-background {
  font-size: 0.7em;
  color: red;
}
.good-background {
  background-color: #66ef441a;
  border-radius: 50px;
  font-size: 0.7em !important;
  height: 1.4rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.good-background {
  font-size: 0.2em;
  color: rgb(0, 165, 74);
}

.ems-content-heading > h1 {
  padding-left: 2rem;
  font-size: 1.5em;
  margin: 1vh 0vw;
  margin-bottom: 4vh;
  /* padding: 2vh 0vw; */
  /* background-color: aquamarine; */
}

/* Sync fusion table style start from here */

.sync-table-header-ems {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* background-color: #2081E3; */
}
.sync-table-header-ems > p {
  margin-right: 8px;
}
.sync-table-header-ems-icon {
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.e-grid .e-headercell .e-headercelldiv {
  color: #2081e2; /* Change to your desired header text color */
  font-size: 1.11em;
}

.e-grid
  .e-gridheader
  .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(
    .e-icon-reorderuparrow
  ):not(.e-icon-reorderdownarrow) {
  color: #2081e2 !important;
}
