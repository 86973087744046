.loader-cmp{
    background-color: rgb(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: spin 1s linear infinite; */
}
.loader-cmp-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 0.5s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }