@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: clamp(0.5rem, 1vw , 0.9rem);
}


@media only screen and (max-width:700px){
  body{
    font-size: 10px ;
  }
} 
*{
  padding: 0;
  margin: 0;
  box-sizing:border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
