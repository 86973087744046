
.sp-contact-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sp-contact-heading{
    margin-top: 2vh;
    /* margin-bottom: 0.; */
}
.sp-contact-heading>h1{
    font-size: 2.3em;
    font-weight: 600;
    /* font-family: Inter; */
}
.sp-contact-heading-btn{
    display: flex;
    align-items: center;
    background-color: #E5E7EB;
    border-radius: 4px;
    padding: 1.5vh 1vw;
    cursor: pointer;
}
.sp-contact-heading-btn>button{
    color: #404040;
    font-weight: 545;
    font-size: 1em;
    cursor: pointer;
    
    background-color: #E5E7EB;
    border: none;
}
.sp-contact-label{
    padding: 1vh 0px;
    /* background-color: aqua; */
    display: flex;
}
.sp-contact-label>p{
    padding: 2px 10px;
    background-color: #2081E233;
    border-radius: 4px;
    color: #262626;
    font-size: 0.8em;
}
.sp-contact-dropdown{
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
    right: 0px;
    width: 200%;
    border-radius: 4px;
    box-shadow: 2px 2px 10px 2px #E5E7EB;
    color: #404040;
    /* padding: 1vh 0px; */
}
.sp-contact-dropdown>ul{
    padding: 0px;
    list-style: none;
}
.sp-contact-dropdown>ul>li{
    cursor: pointer;
    padding: 1.5vh 1vw;
    font-size: 1em;
    font-weight: 500;
    display: flex;
}