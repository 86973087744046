.backup-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

@keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.top{
    display: flex;
    flex-direction: row;
    background-color: rgba(32, 129, 226, 0.1);
    padding: 12px 35px;
    border-radius: 8px 8px 0px 0px;
    font-size: 0.95em;
    transition: margin 0.5s ease-in-out;
    margin-top: 20px;
}

.top1{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 35px;
    font-size: 0.95em;
    border-bottom: 1px solid rgb(233, 233, 233);
    background-color: white;
    animation: slideDown 1s ;
    color: rgb(75, 75, 75);
}
.topMany{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 35px;
    font-size: 0.95em;
    border-bottom: 1px solid rgb(233, 233, 233);
    background-color: white;
    color: rgb(75, 75, 75);
}
.topHead{
    display: flex;
    flex-direction: row;
    padding: 15px 35px;
    font-size: 0.95em;
    border-bottom: 1px solid rgb(233, 233, 233);
    background-color: white;
    animation: slideDown 1s ;
    color: rgb(10, 165, 255);
    font-weight: 600;
}

.maped{
    border-radius: 0px 0px 8px 8px !important;
    overflow: hidden;
}

.maped1 {
    display: grid;
    grid-template-columns: auto auto;
  }
  
  .left-column {
    grid-column: 1; /* Place in the first column */
  }
  
  .right-column {
    grid-column: 2; /* Place in the second column */
  }

  .btnContainer{
    background-color: white;
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: end;
  }