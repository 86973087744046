.import-status-navbar{
    /* background-color: aquamarine; */
    padding: 0px 2.3vw;
    margin: 3vh 0px;
}
.import-status-navbar>ul{
    padding: 0px;
    list-style: none;
    display: flex;
    align-items: center;
}
.import-status-navbar>ul>li{
    margin-top: 3vh;
}
.import-status-navbar>ul>li>a{
    padding: 2vh 0px;
    margin-right: 5vw;
    cursor: pointer;
    font-size: 1.02em;
    font-weight: 550;
    text-decoration: none;
    color: #262626;
}
.import-status-navbar>ul>li>a:hover{
    color: #262626;
    border-bottom: 0.5px solid #8f8b8b;
}

.import-status-navbar>ul>li>p{
    padding: 2vh 0px;
    margin-right: 5vw;
    cursor: pointer;
    font-size: 1.02em;
    font-weight: 550;
    text-decoration: none;
    color: #262626;
}
.import-status-navbar>ul>li>p:hover{
    color: #262626;
    border-bottom: 0.5px solid #8f8b8b;
}


.import-status-navbar>ul>li>a.active{
    border-bottom: 2px solid #2081E2;

}
.import-status-navbar>ul>li>a>span{
    margin-left: 0.8vw;
    padding: 0.5vh 0.8vw;
    background-color: #E5E7EB;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: 500;
}