.add-single-route{
    margin: 1.25em 0px;
    display: flex;
    align-items: center;
}
.add-single-route>p{
    font-size: 0.7em;
    font-weight: 500;
}
.add-single-heading{
    margin: 1em 0px;
}
.add-single-heading>h1{
    font-weight: 600;
}



.add-single-content{
    margin: 5vh 0px;
}