.uploadfilemodal{
    background-color: rgba(148, 139, 139, 0.781);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.uploadfilemodal-content{
    width: 60%;
}
.uploadfilemodal-content-close{
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.uploadfilemodal-content-close>p{
    padding: 10px ;
    border-radius: 100%;
    background-color: grey;
    color: white;
}
.uploadfilemodal-content-content{
    background-color: #ffffff;
    padding: 10px;
}
.uploadfilemodal-content-content>h1{
    font-size: 1.2em;
    font-weight: 400;
    margin: 5px 0px;
}
.uploadfilemodal-content-content>h2{
    margin: 5px 0px;
    font-size: 0.8em;
    font-weight: 400;
}
.uploadfilemodal-content-content-upload{
    border: 1px solid aqua;
    border-radius: 4px;

    padding: 4vh 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    transition: background-color 0.5s;
}
.uploadfilemodal-content-content-upload:hover{
    border: 1px solid #999090;
    background-color: #999090;
}
.uploadfilemodal-content-content-upload>div{
    cursor: pointer;
}
.uploadfilemodal-content-content-upload>h3{
    font-size: .8em;
    font-weight: 400;
    margin: 5px 0px;

}
.uploadfilemodal-content-content-upload>h4{
    font-size: .7em;
    font-weight: 400;

}
.uploadfilemodal-bottom{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 0px;
}
.uploadfilemodal-bottom>button{
    padding: 8px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #4DC36B;
    color: #ffffff;
    font-size: 1em;
    font-weight: 500;
    border: 1px solid #4DC36B;
    transition: background-color 0.4s,color 0.4s;

}
.uploadfilemodal-bottom>button:hover{
    background-color: #ffffff;
    border: 1px solid #4DC36B;
    color: #4DC36B;
}