
.unchanged-contacts-heading>h3{
    color: #404040B2;
    font-size: 0.95em;
    font-weight: 400;
    padding: 4vh 2.3vw;
    border-top: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
}
.unchanged-contacts-content-heading>p{
    padding: 2vh 2.3vw;
    color: #262626;
    font-size: 1.1em;
    font-weight: 400;
    border-bottom: 1px solid #E5E7EB;
}
.unchanged-contacts-content-item>p{
    padding: 2vh 2.3vw;
    color: #545454;
    font-size: 1em;
    font-weight: 400;
    border-bottom: 1px solid #E5E7EB;
}
.unchanged-contacts-content-footer{
    padding: 4vh 2.3vw;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.unchanged-contacts-content-footer>p{
    font-size: 1.2em;
    color: #262626;
}
.unchanged-contacts-content-footer>button{
    padding: 1.5vh 1.5vw;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #E5E7EB;
    color: #404040;
    font-size: 1.2em;
    font-weight: 530;
}