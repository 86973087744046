.match-columns{
    /* background-color: aquamarine; */
    margin: 5vh 0px;
    color: rgb(34, 34, 34);
}
.match-columns>h1{
    color: rgb(22, 22, 22) !important;
    font-size: 2em;
    font-weight: 500;
}
.match-columns-content{
    margin-top: 5vh;
    background-color: #ffffff;
    padding: 2em;
    border-radius: 4px;
}
.match-columns-content-1-heading>h3{
    font-size: 1.2em;
    font-weight: 600;
    line-height: 2em;
}
.match-columns-content-1-heading>p{
    color: grey;
    font-size: 0.9em;
}

.match-columns-content-1-input{
    margin: 5vh 0px;
    position: relative;
}
.match-columns-content-1-input-select>span{
    padding: 5px;
    margin: 0px 5px;
    background-color:#D1D5DB;
    font-size: 0.8em !important;
    /* display: flex;
    align-items: center; */
}
.match-columns-content-1-input>div>p{
    margin-top: 1vh ;
    font-size: 0.8em;
    margin-bottom: 1vh ;
}
.group-search-input{
    display: flex;
    align-items: center;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
}
.group-search-input>input{
    width: 40% ;
    padding: 10px 0.9em;
    outline: none;
    border: none;
    /* border: 1px solid #D1D5DB; */
    /* border-radius: 4px; */
    font-size: 0.8rem;

}
.group-search-input>input:focus{
    border: none;
}
.match-columns-content-1-input>div>input{
    width: 100%;
    padding: 10px 0.9em;
    outline: none;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    font-size: 0.8rem;
}
.choose-value-dropdown{
    position: absolute;
    /* bottom: -215%; */
    width: 100%;
    box-shadow: 2px 2px 10px 5px rgb(221, 217, 217);
    z-index: 1;
    background-color:#ffffff;
    border-radius: 4px;
    overflow: auto;
}
.choose-value-dropdown>ul{
    list-style: none;
    padding: 0px;
    min-height: 1vh;
    max-height: 30vh;
}
.choose-value-dropdown>ul>li{
    font-size: 0.8rem;
    padding: 2vh 1vw;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.choose-value-dropdown>ul>li:hover{
    background-color: #f0eeed;
}
.match-columns-content-2>h3{
    font-size: 1.2em;
    font-weight: 600;
    /* line-height: 2em; */

}
.match-columns-content-2-content-row{
    margin: 5vh 0px;
    /* background-color: aqua; */
}
.match-columns-content-2-content-row-1{
    display: flex;
    align-items: center;
    /* background-color: aqua; */
    margin: 10px 0px;
}
.match-columns-content-2-content-row-1-1{
    width: 70%;
    /* background-color: blueviolet; */
}
/* .match-columns-content-2-content-row-1-1{
    width: 20%;
} */
.match-columns-content-2-content-row-2{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* background-color: aqua; */
    margin: 10px 0px;

}
.match-columns-content-2-content-row-2-1{
    width: 65%;
}

.match-columns-content-2-content-row-2-1>input{
    margin: 5px auto;
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: 1px solid #ECEDF0;
    padding: 10px 10px;
    font-size: 0.8em;
    font-weight: 500;
    color: #6A5B53;
}
.match-columns-content-2-content-row-2-2{
    margin: 5px auto;
    width: 30%;
    /* padding: 2px;
    border-radius: 4px;
    color: rgb(68, 68, 68);
    border: 1px solid #ECEDF0; */
    position: relative;
    /* background-color: blueviolet; */
}
.match-columns-content-2-content-row-2-2>div{
    display: flex;
    /* padding: 6px 10px; */
    justify-content: space-between;
    align-items: center;
}
.match-columns-content-2-content-row-2-2>div>input{
    width: 100%;
    outline: none;
    
    border: none;
}
.match-columns-content-2-content-row-2-2>div>p{
    font-size: 0.8em;
}
.outer-belongstolist{
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 2px 2px 10px 2px rgb(175, 171, 171);
    border-radius: 4px;
    position: absolute;
    top: 38px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.outer-belongstolist>div{
    /* background-color: blueviolet; */
    width: 100%;

}
.outer-belongstolist-ul{
    list-style: none;
    padding: 0px;
    
    
}
.outer-belongstolist-ul>li{
    padding: 1.5vh 1vw;
    /* background-color: aqua; */

}
.outer-belongstolist-ul>li>input{
    width: 100%;
    border-radius: 4px;
    padding: 0.8vw;
    outline: none;
    border: 1px solid black;
}
.belongstolist{
    /* background-color: #ffffff; */
    width: 100%;
    /* top: 38px;
    z-index: 1;
    box-shadow: 2px 2px 10px 2px rgb(175, 171, 171); */
    overflow-y: auto;
    /* position: absolute;
    border-radius: 4px; */
}
.belongstolist::-webkit-scrollbar{
    display: none;
}


.belongstolist>ul{
    min-height: 11vh;
    max-height: 35vh;
    list-style: none;
    padding: 0px;
    /* background-color: aquamarine; */
    width: 100%;
    
    /* background-color: red; */
}
.belongstolist>ul>li{
    padding: 1.5vh 1vw;
    font-size: 0.8em;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    /* background-color: aqua; */
}
.belongstolist>ul>li>input{
    width: 100%;
    outline: none;
    padding: 1vh;
    border-radius: 4px;
    border: 1px solid black;
}
.belongstolist>ul>li:hover{
    background-color: #ECEDF0;
    
}


.match-columns-content-3>h3{
    font-size: 1.2em;
    font-weight: 600;
    color: rgb(48, 48, 48);
}
.match-columns-content-3-content{
    margin: 4vh 0px;
}
.match-columns-content-3-content-1{
    padding: 2vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    /* border: 1px solid #D1D5DB; */
    border-radius: 4px;
}
.match-columns-content-3-content-1>label{
    font-size: 0.8rem !important;
}
.match-columns-content-4{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.match-columns-content-4>button{
    margin: 20px 0px;
    padding: 10px 15px;
    font-size: 0.9em;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}