/* .allsubscriber{
    background-color: #ffffff;
    
} */
.allsubscriber-file{
    background-color: #ffffff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid rgba(229, 231, 235, 1);
    /* align-items: center; */



}
.allsubscriber-file-content{
    background-color: rgba(250, 250, 250, 1);
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 0px;
    align-items: center;
    
}
.allsubscriber-file-mid{
    margin:20px 0px;
    color: grey;
    margin-bottom: 0px;
    /* background-color: red; */
    
}
.allsubscriber-file-mid-2{
    /* background-color: #4DC36B; */
    color: grey;
    /* background-color: red; */
    
}
.allsubscriber-file-bottom-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding: 0px 5px; */
    margin-top: 20px;
    /* background-color: #4DC36B; */
}
.allsubscriber-file-bottom-btn>button{
    padding: 10px 20px;
    margin-left: 5px;
    border-radius: 4px;
    border: none;
    font-size: 0.9em;
    cursor: pointer;

}
#filter-dropdown{
    padding: 10px 20px;
}
.filter-input{
    outline: none;
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
}
/* .filter-input:focus{
    border: none;
} */
.filterlist-li{
    /* border: 1px solid black; */
    cursor: pointer;
    padding: 8px 10px;
    /* font-size: 12px; */
    font-size: 1em;
    color: rgb(119, 119, 119);
}


.filterlist-li:hover{
    background-color: #e6ebf3;

}
.allsubscriber-content{
    background-color: #ffffff;
    margin: 40px 0px;
    padding: 30px 0px;
}
.allsubscriber-content-row-1{
    color: rgb(83, 83, 83);
    margin: 0px 30px;
    font-size: larger;
    display: flex;
    justify-content: space-between;
}
.allsubscriber-content-row-1>ul>li{
    padding: 5px 5px;
    margin-right: 5px;
}
#allsubscriber-content-row-1-li-2{
    padding: 0.6rem 0.8rem;
    /* background-color: #4DC36B; */
    border: 1px solid rgb(230, 230, 230);
    /* display: flex;
    flex-direction: column; */
    /* justify-content: space-between; */
    /* align-items: center; */
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}
#allsubscriber-content-row-1-li-2>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
}
.allsubscriber-content-count{
    color: rgb(75, 75, 75);
    margin: 0px 30px;
    padding: 15px 0px;
}
.allsubscriber-content-action{
    font-size: larger;
    margin: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.allsubscriber-content-action-btn{
    position: relative;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #E5E7EB;
    cursor: pointer;
}
.allsubscriber-content-action-btn>p{
    font-size: 0.9em;
    color: #404040;
}
.allsubscriber-content-table{
    /* background-color: aquamarine; */
    
    /* margin-left:30px; */
    
    padding: 10px 0px;
    /* padding-left: 20px; */

    /* background-color: aqua; */
    /* height: 50vh; */
    overflow-y: scroll;
    /* background-color: aquamarine; */
}


.allsubscriber-content-table>table{
    /* width: 100vw; */
    border:"1px solid black";
    
    /* background-color: blueviolet; */
    
   
    
}
/* .allsubscriber-content-table>table>tr{
    display: flex;
    align-items: center;
    justify-content: space-between;
} */
.le>table>thead>tr{
    border: 10px solid rgb(187, 187, 187);
    background-color: aqua;
}
.allsubscriber-content-table>table>thead>tr>th{
    text-align: left;
    padding: 0.8vh 3vw 1.5vh 0vw ;
    font-weight: 500;
    color: #2081E2;
    font-size: 1.13em;
    min-width: 120px;
    
}

.allsubscriber-content-table>table >tbody>tr>td{
    min-width: 120px;
    text-align: left;
    padding: 0.8vh 0px;
    padding-right: 3vw;
    padding-left: 0vw;
    font-weight: 400;
    color: #696969;
    font-size: 1.05em;
}
.allsubscriber-content-item-left{
    margin: 0px 30px;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.allsubscriber-content-item-left>div>button{
    padding: 12px 22px;
    margin-left: 10px;
    background-color: #ebebeb;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: rgb(75, 75, 75);
    font-weight: 550;
    font-size: 1.1em;
}
.allsubscriber-content-item-left>div>p{
    color: rgb(80, 80, 80);
    font-size: 1.1em;
}
.input-tag{
    padding: 10px 5px;
    border: 1px solid grey;
    border-radius: 4px;
    width: 100%;
    outline: none;
}

.IsExpanedBased{
    background-color:rgba(229, 231, 235, 1);
    border-radius:4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:9vw;
    position: relative;
}
.IsExpanedBased-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:90%;
    padding:0vw 0.5vw;
}
.drop-down{
   position:absolute;
   background-color: white;
   border:1px solid rgba(229, 231, 235, 1);
   left:0;
   top:2.7em;
   width:100%;
   border-radius: 4px;
   z-index: 200;

}
.drop-down-options{
    padding:0.8vw 1vw;
    font-weight: 500;
    font-size: 0.876em;
    color:rgba(64, 64, 64, 0.7)
}
.drop-down-options:hover{
    background-color: rgb(248, 248, 248);
}
.select-header{
    padding:1.5vh 1.5vw;
    background-color:rgba(229, 231, 235, 1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.90em;
    color:rgba(64, 64, 64, 1)

}
.drop-down-check{
    position:absolute;
    background-color: white;
    border:1px solid rgba(229, 231, 235, 1);
    left:0;
    top:0em;
    width:13em;
    border-radius: 4px;
    z-index: 500;
}

/* Add these styles to your CSS file or within a <style> tag in your component */
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the text */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  