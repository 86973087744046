.data-component{
    padding: 2.3vh 2.3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E7EB;
    color: #262626;
    font-size: 1.2em;
}
.data-component-left{
    /* background-color: #2081E2; */
    width: 80%;
    display: flex;
    align-items: center;
}
.data-component-left>input{
    width: 100%;
    padding: 1vh 1vw;
    border-radius: 4px;
    outline: none;
    border: 1px solid #2081E3;
}
.data-component-left>p{
    color: #545454;
    font-size: 0.8em;
    font-weight: 400;
    /* border-bottom: 1px solid #E5E7EB; */
}
.data-component-right{
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
}
.data-component-right-dp{
    /* position: absolute; */
    /* box-shadow: 2px 2px 10px 5px black; */
    background-color: #2081E3;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}
.data-component-right-dp>p{
    font-size: 0.8em;
    color: #ffffff;
    font-weight: 500;
}