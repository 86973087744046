.edit-activity-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* background-color: aquamarine; */
}
.edit-activity-heading>h2{
    font-size: 36px;
}

.edit-activity-route{
    margin: 2vh 0px;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}
.edit-activity-route>p{
    font-size: 0.8em;
    font-weight: 400;
    /* color: #404040E5; */
}
.edit-activity-content{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2vw 2vw;
}
.edit-activity-content-heading{
    color: #262626;
    font-size: 0.8em;
}
.edit-activity-content-content{
    /* background-color: aquamarine; */
    margin-top: 20px;
}

.edit-activity-content-content-row{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E7EB;
    padding: 3vh 0px;
}
.edit-activity-content-content-row-left{
    color: #404040;
    width: 50%;
}
.edit-activity-content-content-row-left>p{
    font-weight: 400;
    font-size: 1em;
}
.edit-activity-content-content-row-right{
    color: #262626;
    width: 50%;
}
.edit-activity-content-content-row-right>p{
    font-weight: 500;
    font-size: 1em;
}

