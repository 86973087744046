.login{
    background-color: #ffffff;
    max-height: 100vh;
    /* height:100%; */
    overflow: hidden;
    /* width: 100vw; */
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.login-content{
    width: 45%;
    /* background-color: aqua; */
    /* padding: 2.5em 2em; */
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.login-content-logo{
    align-self: flex-start;
    padding: 1em 2em;
    /* background-color: pink; */
}
.login-content-logo>img{
    max-width: 100%;
    max-height: 100vh;
}
/* .login-content-form{

} */
/* .login-content-form>h1{
    font-size: 1em;  
    font-family: sans-serif !important;
} */
.login-banner{
    /* height: 100%; */
    position: relative;
    width: 55%;
    height: 100vh;
    /* background-color:blue; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.login-banner>img{
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.login-content-form{
    /* padding: 50px 40px; */
    /* background-color: red; */
    width:72%;
    /* padding: 0em 2em; */
}
.login-content-form>h1{

    /* line-height: 100px; */
    margin-bottom: 0.6em;
    /* background-color: aqua; */
    font-size: 6vh;
    color: #262626;
 
    
}
.login-content-form>h3{
    /* line-height: 60px;
    background-color: aqua; */
    font-weight: 400;
    font-size: 16px;
    color: #404040;
}
.login-content-form-content-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-content-form-content-row>label{
    margin: 10px 0px;
    /* color: rgba(38, 38, 38, 1); */
    color:rgb(38,38,38);
    font-size: 0.9em;
    font-weight: 400;
}
.login-content-form-content-row>input{
    height: 40px;
    border-radius: 6px;
    border: 1px solid #dbdbdc;
    padding: 22px 10px;
    outline: none;
    color:rgb(76,76,76);
}
.login-content-form-content-row>input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Set the background color */
    box-shadow: 0 0 0 1000px white inset; /* Set the background color */
    -webkit-text-fill-color: black; /* Set the text color */
  }
.login-content-form-content-row>input::placeholder{
  color: rgb(218, 214, 214);
}
.login-content-form-content-row-pass{
    /* margin: 15px 0px; */
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #dbdbdc; */
    border-radius: 6px;
    padding: 2px 10px;
}
.login-content-form-content-row-pass>input{
    width: 90%;
    border: none;    
    height: 40px;
    outline: none;
}
.login-content-form-content-row-pass>input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Set the background color */
    box-shadow: 0 0 0 1000px white inset; /* Set the background color */
    -webkit-text-fill-color: black; /* Set the text color */
  }
label{
    font-size: clamp(0.5rem, 2vw, 1rem) !important;
}
.login-content-form-content-row-pass>input::placeholder{
    color: rgb(218, 214, 214);
}
.login-content-form-content-row>input:focus {
    outline: 1px solid #2081E2;
}
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 1.9rem;
    /* padding: 0.4455rem; */
    border: 1px solid rgb(214, 214, 214);
    border-radius: 0.255rem;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 1rem;
    height: 1rem;
    clip-path: polygon(
        10% 59%, 
        33% 87%,  
        83% 40%,  
        79% 18%,  
        34% 64%,  
        15% 47%  
    );
    transform: scale(0);
    background-color:rgb(0, 0, 255);
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
    
  
/* .login-content-form-content-row-pass>input:focus{
    outline: 1px solid #2081E2;
} */
.login-content-form-content-forgot{
    /* background-color: aqua; */
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-content-form-content-row>button{
    padding: 12px;
    font-size: 0.9em;
    font-weight: 600;
    color: #ffffff;
    background-color: #2081E2;
    border: none;
    border-radius: 4px;
    cursor: pointer;

}
.login-content-form-content-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.h11{
    margin: 5px 0px !important;
}
.login-content-form-content-btn-content{
    /* cursor:"pointer", */
    padding: 13px 0px;
    width: 49%;
    /* background-color: #4DC36B; */
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #D1D5DB;
    border-radius: 10px;
    color: #404040;
    cursor: pointer;
}
.login-modal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e4e457;
    /* background-color: transparent; */
    /* z-index: 1; */
}
.login-modal-content{
    padding: 30px;
    background-color: #ffffff;
    width: 30%;
}
.login-banner{
    width: 54%;
}

@media only screen and (max-width:1000px){
    .login{
        background-color: #ffffff;
        min-height: 100vh;
        /* width: 100vw; */
        display: flex;
        justify-content: space-between;
    }
    .login-content{
        width: 100%;
        /* background-color: aqua; */
        padding: 50px 40px;
    }
    .login-banner{display: none;
    }

}