/* .progress-bar-component{
    background-color: aqua;
} */
.progress-bar-component-prog>progress{
    width: 100%;
}

.progress-bar-component-label{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vh 0px;
}