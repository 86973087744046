.action-edit-modal{
    background-color: rgba(59, 57, 57, 0.808);
    display: flex;
    flex-direction: column;
    width: 100vw;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 1vh 0px;
}
.generalconfirmmodal-content-close{
    width: 70% !important;
}
.action-edit-modal-content{
    width: 70%;
    height: 95% !important;
}
.action-edit-modal-content-close{
    position: sticky;
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    top: 0px;
}
.action-edit-modal-content-close>p{
    padding: 10px ;
    border-radius: 100%;
    background-color: grey;
    color: white;
}
.action-edit-modal-content-content{
    background-color: #ffffff;
    padding: 3vh 2vw;
    border-radius: 4px;
}
.action-edit-modal-content-content>div>h1{
    font-size: 1.3em;
    margin: 2vh 0px;
}



.action-edit-modal-content-content-form{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0.7vw 0vw;
}
.action-edit-modal-content-content-form-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}
.action-edit-modal-content-content-form-1-row{
    margin: 1em 0px;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.action-edit-modal-content-content-form-1-row>label{
    color: #262626;
    font-size: 1em !important;
    font-weight: 400;
    margin-bottom: 0.7em;
}
.action-edit-modal-content-content-form-1-row-input{
    position: relative;
}
.action-edit-modal-content-content-form-1-row-input>input{
    width: 100%;
    padding: 0.7em 1em;
    outline: none;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    color: #262626;
    font-size: 1em;
}
.add-new-account-dropdown{
    width: 100%;
    position: absolute;
    background-color: #ebe9e9;
    box-shadow: 1px 3px 8px 2px #d8d8d8;
    z-index: 1;
}
.add-new-account-dropdown>ul{
    padding: 0px;
    list-style: none;
}
.add-new-account-dropdown>ul>li{
    cursor: pointer;
    padding: 10px;
}
.add-new-account-dropdown>ul>li:hover{
    background-color: #D6D6D6;
}





.action-edit-modal-content-content-form-2{
    margin: 0.5em 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #D6D6D6;
    padding-top: 2em;
}
.action-edit-modal-content-content-form-2>button{
    font-weight:550;
    font-size:14px;
    padding:0.8em 1.3em;
    color:white;
    margin-left: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    
}

.add-new-account-dropdown{
    margin-top: 3px;
    width: 100%;
    position: absolute;
    background-color: #fcfcfc;
    box-shadow: 1px 3px 8px 2px #f5f5f5;
    border: 1px solid rgb(238, 238, 238);
    z-index: 999 !important;
}