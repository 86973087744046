.subcriberheader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5vh;
    margin-bottom: 15px;
    
}
.subcriberheader-right{
    position: relative;
    height: 0.1em;
    width: 64%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: blueviolet; */
}

.flex-row{
    position: relative;
    width: 2em;
    height: 2em;
    background-color: rgb(224, 224, 224);
    border-radius: 4px;
    display: flex;
    justify-content: end;
}
.red-dot{
    position: absolute;
    top: -4px;
    right: -4px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #E13B38;
    border: 2px solid #F3F4F6;
}
.bell{
    cursor: pointer;
    display: flex;
    /* background-color: aqua; */
    justify-content: center;
    align-items: center;
    padding: 1vh;
    /* padding-right: 0.5em;
    padding-top: 0.5em ; */
}
.flex-row-1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 9px;
    background-color: #2081E3;
    border-radius: 4px;
    margin-right: 1.5vh;
}
.flex-row-1>p{
    font-size: 1.2em;
    color: #ffffff;
}

.subcriberheader-right-content{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-end;
    margin-right: 1.5vh;
    /* background-color: aqua; */

}
#subcriberheader-right-content-1{
    font-size: 0.9em;
    font-weight: 600;
    line-height: 17px;
    color: #404040;
}
#subcriberheader-right-content-2{
    font-size: 0.8em;
    font-weight: 400;
    line-height: 15px;
    color: #989898;
}
.header-logout{
    width: 15vw;
    position: absolute;
    background-color: #ffffff;
    top: 30px;
    right: 0px;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    z-index: 200;
    box-shadow: 2px 2px 10px 2px #e0dddd;
    border-radius: 4px;
}
.header-logout>ul>li{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 0.8em;
    color: #404040;
    font-weight: 400;
}
.header-logout>ul>li:hover{
    background-color: #E7E8E9;
}
.notification-box{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 1rem;
    width: 64%;
    border: 1px solid rgb(236, 236, 236);
    height: 40vh;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 5px 16px rgba(146, 146, 146, 0.2);
    z-index: 999;
    overflow-y: hidden;
}
.notification-top{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-size: 0.8em;
    border-bottom: 1px solid #F3F4F6;
}
.notification-top p{
    color: gray !important;
    text-decoration: underline;
    cursor: pointer;
}
.overflow{
    height: 36vh;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b;
  }

