.history-component{
    /* background-color: aqua; */
    padding: 4vh 2.5vw;
    border-bottom: 1px solid #E5E7EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.4s;
}

.history-component:hover{
    background-color: #f5f6f8a1;
}
.history-component-left{
    display: flex;
    align-items: center;
}
.history-component-left-2>div{
    display: flex;
    align-items: center;
    color: #404040E5;
    /* background-color: aqua; */
}
.history-component-left-2>div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
}
.history-component-left-2>div>h3{
    font-size: 1.3em;
    font-weight: 500;
    
}
.history-component-left-2>div>h3:hover{
    color: #2081E2;
    cursor: pointer;
}

.history-component-left-2-icon-abbr{
    display:none;
    margin-left: 1vw;
    background-color: #ffffff;
    padding: 0.8vh 0.7vw;
    border-radius: 4px;
    position: absolute;
    box-shadow: 2px 2px 8px 2px #ebe8e8;
    z-index: 1;
    left: 1.5vw;

}
.history-component-left-2-icon:hover>.history-component-left-2-icon-abbr{
    display: flex;
}
.history-component-left-2-icon{
    cursor: pointer;
}
.history-component-left-2>p{
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 5px;
    color: #835461;
}
.history-component-left-2>button{
    margin: 5px 0px;
    padding: 10px 15px;
    background-color: #E5E7EB;
    border: 1px solid #E5E7EB;
    /* border: none; */
    border-radius: 4px;
    font-size: 0.9em;
    font-weight: 520;
    cursor: pointer;
    transition: background-color 0.4s, color 0.4s;
}
.history-component-left-2>button:hover{
    background-color: #ffffff;
    color: #4C4E4C;
    border: 1px solid #E5E7EB;
}
.history-component-right{
    width: 23vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.history-component-right-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-left: 2px solid black; */
    padding-left: 1vw;
    margin-bottom: 10px;
}
.history-component-right-1-1{
    display: flex;
}
.history-component-right-1-1>p{
    margin-left: 10px;
    font-size: 0.9em;
    color: #4F4846;
}
.history-component-right-1-2{
    /* background-color: aqua; */
    width: 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.history-component-right-1-2>div{
    width: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5E7EB;
    /* background-color: aquamarine; */
    border-radius: 4px;
    padding: 5px 10px;
}
.history-component-dropdown{
    right: 0px;
    width: 250%;
    bottom: -40px;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 1px 3px 13px 2px #c1bcbc;
    z-index: 1;
    color: #404040;

}
.history-component-dropdown>ul{
    list-style: none;
    padding: 0px;
}
.history-component-dropdown>ul>li{
    font-size: 1em;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.history-component-dropdown>ul>li:hover{
    background-color: #f2f4f8;
}
.history-component-right-1-2>div>button{
    border: none;
    background-color: #E5E7EB;
    font-size: 0.8em;
    color:#4C4E4C;
    font-weight: 600;
    /*  */
    
}
